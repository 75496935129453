import { FETCH_HEATMAP_GRAPH, FETCH_ENERGY_REVENUE_INSIGHT_GRAPH, FETCH_LIFETIME_DAILY_LIST_API, FETCH_LIFETIME_WEEKLY_LIST_API, FETCH_LIFETIME_MONTHLY_LIST_API, STATION_URL, PREDICTIVE_URL, FETCH_TOTAL_EVSE_STATUS_API, FETCH_TOTAL_CHARGING_API, FETCH_HRS_SESSION_API, FETCH_TOTAL_CITY_SESSION_API, FETCH_SESSION_TYPE_API, FETCH_TOTAL_PASS_FAIL_SESSION_API, CHARGING_SERVICE_URL, FETCH_CITY_COMPARISION_API, FETCH_TOTAL_STATION_SESSION_API, FETCH_STATION_COMPARISION_API, FETCH_WEEKLY_SESSION_API, FETCH_MONTHLY_SESSION_API, FETCH_LIFETIME_DATA_API, FETCH_DATERANGE_SESSION_TYPE, FETCH_DATERANGE_SESSION_STATUS, FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION, FETCH_CUSTOMER_SEARCH_PREDICTION, INSIGHT_PERCENTAGE_CHANGE_API, GET_NOTIFICATION, POST_NOTIFICATION, SAVE_MARK_AS_ALL_READ, FETCH_COUNTRIES_CODE_API, FETCH_TRANSLATION_API, BASE_URL, COMMUNICATION_URL } from "../../components/config/config"

export const fetchCountryCode = () => {
  const { token, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  // console.log(token)
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(BASE_URL + FETCH_COUNTRIES_CODE_API, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(" data from action", data);
      dispatch({ type: 'FETCH_COUNTRY_CODE', payload: data })
    })
  }
}
export const fetchTranslation = () => {
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(BASE_URL + FETCH_TRANSLATION_API, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(" data from action", data);
      dispatch({ type: 'FETCH_TRANSLATION_JSON', payload: data })
    })
  }
}

export const postNotificationAction = (id) => {
  // console.log("id from action",id)
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(COMMUNICATION_URL + POST_NOTIFICATION, {
      method: "POST",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        "id": id,
      })
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'POST_NOTIFICATION_API', payload: data })
    })
  }
}

export const fetchMarkasread = () => {
  const { token, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
  // const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  // console.log(token)
  return dispatch => {
    // console.log(PREDICTIVE_URL + SAVE_MARK_AS_ALL_READ + "?userid=" + userid + "&operator=" + organisation + "&role=" + role)
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(BASE_URL + SAVE_MARK_AS_ALL_READ + "?userid=" + userid + "&operator=" + organisation + "&role=" + role, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(" data from action", data);
      dispatch({ type: 'MARK_AS_READ_API', payload: data })
    })
  }
}

export const fetchNotificationDataAPI = () => {
  const { token, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  console.log(token)
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(COMMUNICATION_URL + GET_NOTIFICATION + "?userid=" + userid + "&role=" + role + "&operator=" + organisation, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(" data from action",data);
      dispatch({ type: 'FETCH_NOTIFICATION_API', payload: data })
    })
  }
}
// export const fetchNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'FETCH_NOTIFICATION_COUNT'})
//     }
// }
// export const addNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'ADD_NOTIFICATION_COUNT', payload: data})
//     }
// }
// export const removeNotificationCount = (data) => {
//   return dispatch => {
//     dispatch({type:'INSIGHT_LOADING',payload:''})
//      return dispatch({type: 'REMOVE_NOTIFICATION_COUNT', payload: data})
//     }
// }

export const fetchHeatMapGraph = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_HEATMAP_GRAPH + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_HEATMAP_GRAPH', payload: data })
    })
  }
}


export const fetchEnergyAndRevenueGraph = (start, end) => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_ENERGY_REVENUE_INSIGHT_GRAPH + "?operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_ENERGY_REVENUE_INSIGHT_GRAPH', payload: data })
    })
  }
}



export const fetchLifeTimeDailyList = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_LIFETIME_DAILY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_LIFETIME_DAILY_LIST', payload: data })
    })
  }
}

export const fetchLifeTimeWeeklyList = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_LIFETIME_WEEKLY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_LIFETIME_WEEKLY_LIST', payload: data })
    })
  }
}

export const fetchLifeTimeMonthlyList = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(org);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_LIFETIME_MONTHLY_LIST_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data from action",data)
      dispatch({ type: 'FETCH_LIFETIME_MONTHLY_LIST', payload: data })
    })
  }
}

export const fetchEVSEStatusAction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(STATION_URL + FETCH_TOTAL_EVSE_STATUS_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&role=" + role, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_CHARGER_EVSE_STATUS', payload: data })
    })
  }
}

export const fetchChargingStatusAction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_TOTAL_CHARGING_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'LOAD_TOTAL_CHARGING_STATUS', payload: data })
    })
  }
}

export const fetchHrsSessionTypeAction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_HRS_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_HRS_SESSION', payload: data })
    })
  }
}

export const fetchCitySessionAction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token;
  return dispatch => {
    dispatch({ type: 'INSIGHT_CITY_GRAPH_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_TOTAL_CITY_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_TOTAL_CITY_CONSUMPTION', payload: data })
    })
  }
}

export const fetchStationSessionAction = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_STATION_GRAPH_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_TOTAL_STATION_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_TOTAL_STATION_CONSUMPTION', payload: data })
    })
  }
}



export const fetchSessionTypeAction = () => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_SESSION_TYPE_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'LOAD_SESSION_TYPE', payload: data })
    })
  }
}

export const fetchPssFailSessionAction = () => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_TOTAL_PASS_FAIL_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("LOAD_TOTAL_SESSION",data)
      dispatch({ type: 'LOAD_TOTAL_SESSION', payload: data })
    })
  }
}

export const fetchCityComparisionAction = (city1, city2, start_date, end_date) => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_CITY_GRAPH_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + FETCH_CITY_COMPARISION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&city1=" + city1 + "&city2=" + city2 + "&start_date=" + start_date + "&end_date=" + end_date, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_CITY_COMPARISION', payload: data })
    })
  }
}

export const fetchStationComparisionAction = (station1, station2, start_date, end_date) => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  // console.log(CHARGING_SERVICE_URL + FETCH_STATION_COMPARISION_API + "?station1="+station1+"&station2="+station2+"&start_date="+start_date+"&end_date=" + end_date)
  return dispatch => {
    dispatch({ type: 'INSIGHT_STATION_GRAPH_LOADING', payload: '' })
    return fetch(CHARGING_SERVICE_URL + FETCH_STATION_COMPARISION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&station1=" + station1 + "&station2=" + station2 + "&start_date=" + start_date + "&end_date=" + end_date, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_STATION_COMPARISION', payload: data })
    })
  }
}

export const fetchWeekSessionTypeAction = (start, end) => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_WEEKLY_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("week", data)
      dispatch({ type: 'LOAD_WEEK_SESSION', payload: data })
    })
  }
}

export const fetchMonthSessionTypeAction = (start, end) => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    // console.log(PREDICTIVE_URL + FETCH_MONTHLY_SESSION_API + "?operator=" + operator + "&start=" + start + "&end=" + end)
    return fetch(PREDICTIVE_URL + FETCH_MONTHLY_SESSION_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("month", data)
      dispatch({ type: 'LOAD_MONTH_SESSION', payload: data })
    })
  }
}

export const fetchLifetimeEnergyRevenue = () => {
  const { token, organisation, role, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(token);
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_REVENUE_GRAPH_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_LIFETIME_DATA_API + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("LOAD_LIFETIME_ENERGY_REVENUE", data)
      dispatch({ type: 'LOAD_LIFETIME_ENERGY_REVENUE', payload: data })
    })
  }
}


export const fetchSessionTypeByDate = (start, end) => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_DATERANGE_SESSION_TYPE + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("data", data)
      dispatch({ type: 'FETCH_DATERANGE_SESSION_TYPE', payload: data })
    })
  }
}

export const fetchSessionStatusByDate = (start, end) => {
  const { token, role, organisation, region } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const region1 = role === 'Regional Manager' ? (region[0] || "") : "";
  const region2 = role === 'Regional Manager' ? (region[1] || "") : "";
  const region3 = role === 'Regional Manager' ? (region[2] || "") : "";
  const region4 = role === 'Regional Manager' ? (region[3] || "") : "";

  // console.log(token)
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_DATERANGE_SESSION_STATUS + "?region1=" + region1 + "&region2=" + region2 + "&region3=" + region3 + "&region4=" + region4 + "&operator=" + operator + "&start=" + start + "&end=" + end, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'FETCH_DATERANGE_SESSION_STATUS', payload: data })
    })
  }
}

export const fetchCustomerTop5SearchResult = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log(data)
      dispatch({ type: 'FETCH_CUSTOMER_TOP5_SEARCH_PREDICTION', payload: data })
    })
  }
}

export const fetchCustomerAllSearchResult = () => {
  const { token } = JSON.parse(localStorage.getItem('user'));
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + FETCH_CUSTOMER_SEARCH_PREDICTION, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      dispatch({ type: 'FETCH_CUSTOMER_ALL_SEARCH_PREDICTION', payload: data })
    })
  }
}


export const insightPercentageChange = () => {
  const { token, organisation, role } = JSON.parse(localStorage.getItem('user'));
  const operator = role === 'Admin' ? 'All' : organisation;
  const bearer = 'basic ' + token
  return dispatch => {
    dispatch({ type: 'INSIGHT_LOADING', payload: '' })
    return fetch(PREDICTIVE_URL + INSIGHT_PERCENTAGE_CHANGE_API + "?operator=" + operator, {
      method: "GET",
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    }).then(resp => resp.json()).then(data => {
      // console.log("afterhit", data)
      dispatch({ type: 'INSIGHT_PERCENTAGE_CHANGE', payload: data })
    })
  }
}