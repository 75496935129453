
import React, {  useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import "../../reacttable/reactTable.css";
import Table from "../../reacttable/table";
import { useTranslation } from "react-i18next";
import moment from "moment";

function CustomerBookingList(props) {
    // console.log(props)
    const { t } = useTranslation();
    const activeBooking = props.activeBooking && props.activeBooking.data;
    // console.log("activeBooking", activeBooking)
    const [currentDate, setCurrentDate] = useState(new Date());
    const todaysDate = moment(currentDate).format('DD/MM/YYYY');
    // console.log("props.delete.statuscode",props.delete.statuscode)

    const handleDelete = (reserveid) => {
        // console.log(reserveid)
        const isConfirmed = window.confirm("Are you sure you want to Cancle the booking?");

        if (isConfirmed) {
            props.BookingDelete(reserveid);
        } else {
        }
    }


    if (props.delete.statuscode === 200) {
        window.location.reload();
    }

    const activeBookingcolumns = useMemo(
        () => [
            { Header: "User Name", accessor: "loginid" },
            { Header: "Station Name", accessor: "name" },
            { Header: "EVSE ID", accessor: "evse_id" },
            { Header: "Address", accessor: "address" },
            { Header: "City", accessor: "city" },
            { Header: "Booking ID", accessor: "bookingid" },
            { Header: "Reservation ID", accessor: "reserveid" },
            { Header: "Date", id: "date1", accessor: "date" },
            { Header: "Start", accessor: "begin" },
            { Header: "End", accessor: "end" },
            // {
            //     Header: 'Booking Status',
            //     id: 'date2',
            //     accessor: 'date',
            //     Cell: ({ value }) => {
            //         const [valueDay, valueMonth, valueYear] = value.split('/');
            //         const [todaysDay, todaysMonth, todaysYear] = todaysDate.split('/');

            //         if (
            //             (+valueYear < +todaysYear) ||
            //             (+valueYear === +todaysYear && +valueMonth < +todaysMonth) ||
            //             (+valueYear === +todaysYear && +valueMonth === +todaysMonth && +valueDay < +todaysDay)
            //         ) {
            //             return <span style={{ color: 'red' }}>Booking Expired</span>;
            //         } else {
            //             return <span style={{ color: 'green' }}>Booking Scheduled</span>;
            //         }
            //     },
            // },
            // {
            //     Header: 'Cancel Reservation',
            //     id: "reserveid1",
            //     accessor: 'reserveid',
            //     Cell: ({ row }) => {
            //         const { original } = row;
            //         const status = original.status;
            //         // console.log("status",status)
            //         if (status === "RESERVE") {
            //             return (
            //                 <div>
            //                     <span>
            //                         <i style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDelete(row.original.reserveid)}>
            //                             &#10060;
            //                         </i>
            //                     </span>
            //                 </div>
            //             );
            //         } else {
            //             return null;
            //         }
            //     },

            // },
            {
                Header: "Status", accessor: "status",
                Cell: (stats) => {
                    return t(stats.value)
                }
            },

        ],
        []
    );
    return (

        <Card className="customercard">
            <div className="table-header">{t("Customer Booking Data")}</div>
            {props.loading ? <h3 style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "350px",
            }}>Loading data...</h3> :
                activeBooking && activeBooking.length > 0 ? (
                    <div>
                        <Table
                            data={activeBooking}
                            columns={activeBookingcolumns}
                        ></Table>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <h3 className="loader">{t("No data found")}</h3>
                    </div>
                )}
        </Card>
    )

}

export default CustomerBookingList;