import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "react-dates/initialize";
import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import "./report.css";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { allOrgTariffAction } from "../../../store/actions/organisationAction";
import PureBreadcrumbs from "../breadcrums";
import moment from "moment";
import CustomPaginationTable from "./adminReport/customPaginationTable";
import { fetchAllFailedTransactionAction, fetchFailedTransactionAction } from "../../../store/actions/reportActions";


function FailedTransactionReport(props) {
    const { t } = useTranslation();
    const oneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week
    const currentDate = new Date(); // Get the current date and time
    const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Get tomorrow's date
    const [startDate, setStartDate] = useState(new Date(currentDate.getTime() - oneWeekInMilliseconds)); // Set startDate to one week before currentDate
    const [endDate, setEndDate] = useState(tomorrowDate);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { role, organisation } = JSON.parse(localStorage.getItem('user'));
    const [org, setOrg] = useState("All");
    const operator = role === 'Admin' ? 'All' : organisation;


    // console.log("Role & operator", role, operator)
    // console.log("end date", endDate)

    const handleChange = (e) => {
        const data = e.value;
        setOrg(data);
    };
    const options = [
        { value: "All", label: "All" },
        ...(props.orgdata && props.orgdata.organisations ? props.orgdata.organisations.map(item => ({
            value: item.name,
            label: item.name
        })) : [])
    ];

    const data = props.all && props.all.data;
    // console.log("data", data);
    const cardData = props.all && props.all;
    const reportCount = props.all && props.all.report_count;
    const defaultPageSize = 10;
    const pageCount = Math.ceil(reportCount / defaultPageSize)
    const totalFailed = cardData && cardData.total_failed
    const totalSession = cardData && cardData.total_session

    useEffect(() => {
        const startDateStr = startDate.toISOString().substr(0, 10);
        const endDateStr = endDate.toISOString().substr(0, 10);

        const fetchData = () => {
            props.getOrg();
            props.list(startDateStr, endDateStr, role === 'Admin' ? org : operator, currentPage, pageSize);
            props.csvDataList(startDateStr, endDateStr, role === 'Admin' ? org : operator, currentPage, reportCount);
        };

        fetchData();
    }, [org, currentPage, pageSize]);



    const loadNewData = () => {
        const startDateStr = startDate.toISOString().substr(0, 10);
        const endDateStr = endDate.toISOString().substr(0, 10);
        if (startDateStr && endDateStr) {
            props.list(startDateStr, endDateStr, role === 'Admin' ? org : operator, currentPage, pageSize);
        }
    };

    const csvData = props.csvDataRes && props.csvDataRes.data;
    // console.log("csvData: ", csvData);

    const columns = useMemo(
        () => [
            { Header: "ID", accessor: "charging_id", id: "charging_idd" },
            {
                Header: 'Date',
                accessor: 'createat',
                Cell: (cell) => {
                    const date = cell.cell;
                    return moment(date).local().format("DD-MM-YYYY");
                },
            },
            { Header: "Name", accessor: "customer_name" },
            { Header: "Customer No.", accessor: "mobile" },
            { Header: "Station Name", accessor: "stationid" },
            { Header: "ChargeBox ID", accessor: "evse_id" },
            { Header: "Connector ID", accessor: "portno" },
            { Header: "Operator", accessor: "operator" },
            { Header: "City", accessor: "city" },
            { Header: "Status", accessor: "status" },
            { Header: "Reason", accessor: "reason" },
            { Header: "Description", accessor: "description" },
        ], []
    );

    const headers = [
        { label: "ID", key: "charging_id" },
        { label: "Date", key: "createat" },
        { label: "Name", key: "customer_name" },
        { label: "Customer No.", key: "mobile" },
        { label: "Station Name", key: "stationid" },
        { label: "ChargeBox ID", key: "evse_id" },
        { label: "Connector ID", key: "portno" },
        { label: "Operator", key: "operator" },
        { label: "City", key: "city" },
        { label: "Reason", key: "reason" },
        { label: "Description", key: "description" },

    ];

    const handleCSVLinkClick = async () => {
        // console.log("csv link clicked");
        props.csvDataList(startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10), org, currentPage, reportCount);
    };

    return (
        <Container fluid className="pb-4">
            <PureBreadcrumbs />
            <Row className="mt-2">
                <Col className="col-md-6 col-12">
                    <Card className="customercard card-custom total-rules">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL SESSIONS")}</Card.Text>
                            <Card.Title>
                                <b>{totalSession !== undefined ? totalSession : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
                <Col className="col-md-6 col-12">
                    <Card className="customercard card-custom total-inactive">
                        <div className="diagonal-line"></div>
                        <div style={{ textAlign: "left" }}>
                            <Card.Text style={{ fontSize: "16px" }}>{t("TOTAL FAILED SESSIONS")}</Card.Text>
                            <Card.Title>
                                <b>{totalFailed !== undefined ? totalFailed : 0}</b>
                            </Card.Title>
                        </div>
                    </Card>
                    <br />
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <Card className="customercard">
                        <Card.Header>
                            <Row>
                                <Col md={2} sm={12}>
                                    {t("Failed Transaction Report")}
                                </Col>
                                <Col md={10} sm={12}>
                                    <div className="row justify-content-end">
                                        {role === 'Admin' &&
                                            <div div style={{ paddingRight: "10px" }} >
                                                <ReactSelect
                                                    options={options ? options : []}
                                                    // className={`${errors.currency ? 'is-invalid' : ''}`}
                                                    name="org"
                                                    id="floatingInputGridOrg"
                                                    placeholder={org}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderColor: state.isFocused ? 'grey' : 'grey',
                                                            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : '',
                                                            height: 'calc(1.5em + 0.75rem + 2px)',
                                                            minHeight: 'calc(1.5em + 0.75rem + 2px)',
                                                        }),
                                                        valueContainer: (baseStyles) => ({
                                                            ...baseStyles,
                                                            height: 'calc(1.5em + 0.75rem + 2px)',
                                                            padding: '0 0.75rem',
                                                            width: "10rem"
                                                        }),
                                                        placeholder: (baseStyles) => ({
                                                            ...baseStyles,
                                                            whiteSpace: 'nowrap',
                                                        }),
                                                        input: base => ({
                                                            ...base,
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            '& input': {
                                                                border: 'none !important',
                                                            },
                                                        }),
                                                        menu: base => ({
                                                            ...base,
                                                            width: "12.5rem"
                                                        }),
                                                        menuPortal: base => ({
                                                            ...base,
                                                            width: "12.5rem"
                                                        }),

                                                    }}
                                                    onChange={handleChange}
                                                />

                                            </div>}
                                        <div className="row justify-content-center">
                                            <div className="col-auto ">
                                                <DatePicker
                                                    className="gray-border calendar"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    selectsStart
                                                    startDate={startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <span style={{ paddingTop: "7px" }}> To </span>
                                            <div className="col-auto">
                                                <DatePicker
                                                    className="gray-border calendar"
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={startDate}
                                                    showDisabledMonthNavigation
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={tomorrowDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="csvbutton ml-3 ">
                                            <button className="btn btn-sm" onClick={loadNewData}>
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#676767",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    GO
                                                </span>
                                            </button>
                                        </div>
                                        <div className="mt-1 ">
                                            {props.csvDataRes && props.csvDataRes.data != null ? (
                                                <CSVLink
                                                    data={csvData}
                                                    headers={headers}
                                                    filename={
                                                        // org +
                                                        // "_" +
                                                        // startDate.toISOString().substr(0, 10) +
                                                        // "_" +
                                                        // endDate.toISOString().substr(0, 10) +
                                                        `Failed Transaction Report.csv`
                                                    }
                                                    target="_blank"
                                                    onClick={handleCSVLinkClick}
                                                >
                                                    <i className="fas fa-2x fa-file-csv"></i>
                                                </CSVLink>
                                            ) : (
                                                <i className="fas fa-2x fa-file-csv"></i>
                                            )}
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="evse">
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "450px",
                            }}>
                                Loading...
                            </h3> :
                                props.all && props.all.data != null ? (
                                    <div>
                                        {/* <AdminTable data={data} columns={columns}></AdminTable> */}
                                        <CustomPaginationTable
                                            data={data}
                                            allData={csvData}
                                            pageCount={pageCount}
                                            columns={columns}
                                            search={search}
                                            setSearch={setSearch}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            filteredData={filteredData}
                                            setFilteredData={setFilteredData}
                                            loading={props.loading}
                                            t={t}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "450px",
                                        }}
                                    >
                                        <h3>{t("No Data Found")}</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        orgdata: state.operator.organisationsList,
        all: state.report.fetchFailedTransaction,
        csvDataRes: state.report.fetchAllFailedTransaction,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOrg: () => dispatch(allOrgTariffAction()),
        list: (start, end, org, currentPage, pageSize) => dispatch(fetchFailedTransactionAction(start, end, org, currentPage, pageSize)),
        csvDataList: (start, end, org, currentPage, reportCount) => dispatch(fetchAllFailedTransactionAction(start, end, org, currentPage, reportCount)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FailedTransactionReport);
