import { CUSTOMER_FEEDBACK_API, CUSTOMER_URL, FETCH_CUSTOMER_LIST_API, FETCH_ALL_RFID_API, SAVE_RFID_API, FETCH_SINGLE_CUSTOMER_DATA_API, UPDATE_CUSTOMER_API, CUSTOMER_PERCENTAGE_CHANGE_API, CHARGING_SERVICE_URL, FETCH_USER_CHARGING_HISTORY_API, CUSTOMER_AUTHENTICATOIN_LOG_API, CUSTOMER_ACTIVATE_ACCOUNT_API, CUSTOMER_DEACTIVATE_ACCOUNT_API, UPDATE_ADMIN_STATUS, GET_ADMIN_STATUS, UPDATE_RFID_API, ACTIVATE_RFID, DEACTIVATE_RFID, SAVE_CUSTOMER_GROUP, FETCH_ALL_CUSTOMER_GROUP, FETCH_SINGLE_CUSTOMER_GROUP_DATA, UPDATE_CUSTOMER_GROUP, ACTIVATE_GROUP_API, DEACTIVATE_GROUP_API, FETCH_RFID_LIST_API, CREATE_RFID_BINDING_WITH_CUSTOMER_API, GUEST_USER_SIGNUP_API, FETCH_SINGLE_RFID_API } from "../../components/config/config";

export const customerFeedback = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + CUSTOMER_FEEDBACK_API + "?operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'CUSTOMER_FEEDBACK_API', payload: data })
        })
    }
}


export const allCutomersAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_CUSTOMER_LIST_API + "?operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'LOAD_CUSTOMER_LIST', payload: data })
        })
    }
}

export const activateGroup = (group_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + ACTIVATE_GROUP_API + "?group_name=" + group_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'ACTIVATE_CUSTOMER_GROUP', payload: data })
        })
    }
}
export const deactivateGroup = (group_name) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + DEACTIVATE_GROUP_API + "?group_name=" + group_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'DEACTIVATE_CUSTOMER_GROUP', payload: data })
        })
    }
}

export const getAllCustomerGroupingList = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_ALL_CUSTOMER_GROUP + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ALL_CUSTOMER_GROUP', payload: data })
        })
    }
}
export const getSingleCustomerGroupingList = (group_name) => {
    // console.log("group name from action",group_name)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    // const operator = role === 'Admin' ? 'All' : organisation;
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_SINGLE_CUSTOMER_GROUP_DATA + "?group_name=" + group_name, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_SINGLE_CUSTOMER_GROUP', payload: data })
        })
    }
}

export const updateCustomerGrouping = (flatRowData, isChecked, sub_name, tariffName, groupName) => {
    // console.log(flatRowData,tariffName,groupName)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + UPDATE_CUSTOMER_GROUP, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "group_name": groupName,
                "tariff": tariffName,
                "customer": flatRowData,
                "is_valid_subscription": isChecked,
                "subscription_name": sub_name,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'UPDATE_SINGLE_CUSTOMER_GROUP', payload: data })
        })
    }
}
export const saveCustomerGrouping = (flatRowData, isChecked, sub_name, tariffName, groupName) => {
    // console.log(flatRowData,tariffName,groupName)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + SAVE_CUSTOMER_GROUP, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "role": role,
                "operator": organisation,
                "group_name": groupName,
                "tariff": tariffName,
                "customer": flatRowData,
                "is_valid_subscription": isChecked,
                "subscription_name": sub_name,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'SAVE_CUSTOMER_GROUP', payload: data })
        })
    }
}

export const singleCutomersAction = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_SINGLE_CUSTOMER_DATA_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'LOAD_SINGLE_CUSTOMER', payload: data })
        })
    }
}

export const allRFIDAction = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_ALL_RFID_API + "?role=" + role + "&organisation=" + organisation, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'LOAD_RFID_LIST', payload: data })
        })
    }
}
export const getStatus = (rateId) => {
    // console.log("id from status console",rateId)
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + GET_ADMIN_STATUS + "?rate_id=" + rateId, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'FETCH_ADMIN_STATUS', payload: data })
        })
    }
}

export const saveRfidAction = (data) => {
    const { rfid, commission, currency, isAmountBased, isPaymentCollectedOffline, isRfidFreeCharger, rfidRechargeAmount, iskWhBased, kWhUsage, kWhAmount, isTimeBased, time, timeRechargeAmount, rfidType, postpaidDuration, station_name } = data
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + SAVE_RFID_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "rfid": rfid,
                'commission': new Date(commission).toLocaleDateString(),
                "currency": currency,
                "amount_based": isAmountBased,
                "free_charging": isRfidFreeCharger,
                'amount': Number(rfidRechargeAmount),
                "kwh_based": iskWhBased,
                "kwh": Number(kWhUsage),
                "kwh_amount": Number(kWhAmount),
                "time_based": isTimeBased,
                "time": Number(time),
                "time_amount": Number(timeRechargeAmount),
                "type": isRfidFreeCharger ? "prepaid" : rfidType,
                "sub_duration": postpaidDuration,
                "station": station_name,
                "offline_payment": isPaymentCollectedOffline,
                'role': role,
                "organisation": organisation
            })
        }).then(resp => resp.json()).then(data => {
            dispatch({ type: 'SAVE_RFID', payload: data })
        })
    }
}
export const updateRfidAction = (data, id) => {
    // console.log("data from action", data, id)
    const { rfid, commission, currency, isAmountBased, isPaymentCollectedOffline, isRfidFreeCharger, amount, iskWhBased, kWhUsage, kWhAmount, isTimeBased, time, timeRechargeAmount, rfidType, postpaidDuration, station_name } = data
    // console.log(commission)
    // console.log(new Date(commission).toLocaleDateString())
    const { token, userid, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + UPDATE_RFID_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": id,
                "rfid": rfid,
                // "userid": userid,
                'commission': commission,
                "currency": currency,
                "amount_based": isAmountBased,
                "free_charging": isRfidFreeCharger,
                'amount': Number(amount),
                "kwh_based": iskWhBased,
                "kwh": Number(kWhUsage),
                "kwh_amount": Number(kWhAmount),
                "time_based": isTimeBased,
                "time": Number(time),
                "time_amount": Number(timeRechargeAmount),
                "type": isRfidFreeCharger ? "prepaid" : rfidType,
                "sub_duration": postpaidDuration,
                "station": station_name,
                "offline_payment": isPaymentCollectedOffline,
                'role': role,
                "organisation": organisation
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("RFID update action data", data);
            dispatch({ type: 'UPDATE_RFID', payload: data })
        })
    }
}

export const fetchSingleRfidApi = (id) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_SINGLE_RFID_API + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("WEf", data)
            dispatch({ type: 'FETCH_SINGLE_RFID_API', payload: data })
        })
    }
}

export const activateRfid = (id) => {
    console.log("id", id);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + ACTIVATE_RFID + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'ACTIVATE_RFID', payload: data })
        })
    }
}
export const deactivateRfid = (id) => {
    console.log("id", id);
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + DEACTIVATE_RFID + "?id=" + id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log(data)
            dispatch({ type: 'DEACTIVATE_RFID', payload: data })
        })
    }
}
export const saveFeedbackStatusUpdate = (data) => {
    // console.log("data from save status", data)
    const { extraId, adminFeedback, adminStatus } = data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + UPDATE_ADMIN_STATUS, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "id": extraId,
                "status": adminStatus,
                "admin_comment": adminFeedback,
            })
        }).then(resp => resp.json()).then(data => {
            // console.log(data);
            dispatch({ type: 'SAVE_ADMIN_STATUS', payload: data })
        })
    }
}

export const updateCustomerProfile = (userid, u_data) => {
    // console.log("userid", userid)
    const { firstname, lastname, email, mobile, address, city, state, country, accounttype, postal_code, corporate_code } = u_data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + UPDATE_CUSTOMER_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid": userid,
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "mobile": mobile,
                "address": address,
                "city": city,
                "state": state,
                "country": country,
                "accounttype": accounttype,
                "postal_code": postal_code,
                "corporate_code": corporate_code
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("UPDATE_CUSTOMER_PROFILE", data)
            dispatch({ type: 'UPDATE_CUSTOMER_PROFILE', payload: data })
        })
    }
}

export const getPercentageChangeData = () => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token;

    return async dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' });

        try {
            const response = await fetch(CUSTOMER_URL + CUSTOMER_PERCENTAGE_CHANGE_API + "?role=" + role + "&organisation=" + organisation, {
                method: "GET",
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });

            // Check if the response is OK (status in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            dispatch({ type: 'CUSTOMER_PERCENTAGE_CHANGE_API', payload: data });
        } catch (error) {
            // handle errors
            console.error('Error fetching percentage change data:', error);
        }
    }
}

export const getUserChargingHistory = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CHARGING_SERVICE_URL + FETCH_USER_CHARGING_HISTORY_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("WEf", data)
            dispatch({ type: 'FETCH_USER_CHARGING_HISTORY', payload: data })
        })
    }
}

export const getUserAuthLog = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + CUSTOMER_AUTHENTICATOIN_LOG_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("WEf", data)
            dispatch({ type: 'FETCH_USER_AUTHENTICATION_LOG', payload: data })
        })
    }
}

export const activateAccount = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + CUSTOMER_ACTIVATE_ACCOUNT_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("activate action data",data)
            dispatch({ type: 'ACTIVATE_USER_ACCOUNT', payload: data })
        })
    }
}
export const deactivateAccount = (userid) => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + CUSTOMER_DEACTIVATE_ACCOUNT_API + "?userid=" + userid, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("deactivate action data",data)
            dispatch({ type: 'DEACTIVATE_USER_ACCOUNT', payload: data })
        })
    }
}
export const fetchRfidList = () => {
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + FETCH_RFID_LIST_API, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        }).then(resp => resp.json()).then(data => {
            // console.log("WEf", data)
            dispatch({ type: 'FETCH_ALL_RFID_API', payload: data })
        })
    }
}
export const createRfidbindingApi = (data) => {
    const { username, rfidNo } = data
    const { token } = JSON.parse(localStorage.getItem('user'));
    const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + CREATE_RFID_BINDING_WITH_CUSTOMER_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "userid": username,
                "rfid": rfidNo,
                // "currency": currency,
                "amount": 0,
            })
        }).then(resp => resp.json()).then(data => {
            console.log("SAVE_RFID_BINDING_API", data)
            dispatch({ type: 'SAVE_RFID_BINDING_API', payload: data })
        })
    }
}
export const createGuestUserApi = (data) => {
    const { email, firstName, lastName, mobile, confirmPassword } = data
    // console.log("data from action", data)
    const { token } = JSON.parse(localStorage.getItem('user'));
    // const bearer = 'basic ' + token
    return dispatch => {
        dispatch({ type: 'LOAD_CUSTOMER', payload: '' })
        return fetch(CUSTOMER_URL + GUEST_USER_SIGNUP_API, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "firstname": firstName.trim(),
                "lastname": lastName.trim(),
                "password": confirmPassword,
                "email": email,
                "userid": email,
                "mobile": Number(mobile),
                "agree": true,
                "accounttype": "individual",
                "corporate_code": '',
                "devicetoken": '',
                "communication": true
            })
        }).then(resp => resp.json()).then(data => {
            // console.log("SAVE_CUSTOMER_ACCOUNT", data)
            dispatch({ type: 'SAVE_CUSTOMER_ACCOUNT', payload: data })
        })
    }
}