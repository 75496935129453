import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { fetchSingleReportRfidAction } from '../../../../store/actions/reportActions';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import PureBreadcrumbs from "../../breadcrums";


const ViewReportSingleRfid = (props) => {
    const { rfid } = useParams();
    // console.log("rfid", rfid);

    useEffect(() => {
        props.fetchSingleRfid(rfid);
    }, [rfid])

    const data = props.fetchSingleRfidRes && props.fetchSingleRfidRes.data
    // console.log(data)

    return (
        <Container className="">
            <PureBreadcrumbs />
            <Row className='pt-4'>
                <Col lg={12}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">User Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formUserId">
                                        <Form.Label>User ID</Form.Label>
                                        <Form.Control type="email" value={data.userid || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRfid">
                                        <Form.Label>RFID</Form.Label>
                                        <Form.Control type="text" value={data.rfid || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRegisterAt">
                                        <Form.Label>Register At</Form.Label>
                                        <Form.Control type="text" value={moment(data.registerat).format('DD/MM/YYYY')} readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formCurrency">
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Control type="text" value={data.currency || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formAmountBased" style={{ paddingTop: "2.5rem" }}>
                                        {/* <Form.Label>Amount Based</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.amount_based || "Not available"} label="Amount Based" readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">Amount Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formAmountRecharge">
                                        <Form.Label>Amount Recharge</Form.Label>
                                        <Form.Control type="number" value={data.amount_recharge} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formAmount">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control type="number" value={data.amount} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formFreeCharging" style={{ paddingTop: "2.5rem" }}>
                                        {/* <Form.Label>Free Charging</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.free_charging} label="Free Charging" readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">KWh Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formKwhBased" style={{ paddingTop: "2.5rem" }}>
                                        {/* <Form.Label>KWh Based</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.kwh_based} label="KWh Based" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formKwhRecharge">
                                        <Form.Label>KWh Recharge</Form.Label>
                                        <Form.Control type="number" value={data.kwh_recharge} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formKwh">
                                        <Form.Label>KWh</Form.Label>
                                        <Form.Control type="number" value={data.kwh} readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">Time Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formTimeBased" style={{ paddingTop: "2.5rem" }}>
                                        {/* <Form.Label>Time Based</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.time_based} label="Time Based" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formTimeRecharge">
                                        <Form.Label>Time Recharge</Form.Label>
                                        <Form.Control type="number" value={data.time_recharge} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formTime">
                                        <Form.Label>Time</Form.Label>
                                        <Form.Control type="number" value={data.time} readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">RFID & Payment Information</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formStation">
                                        <Form.Label>Station</Form.Label>
                                        <Form.Control type="text" value={data.station || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRfidType">
                                        <Form.Label>RFID Type</Form.Label>
                                        <Form.Control type="text" value={data.rfid_type || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRfidDuration">
                                        <Form.Label>RFID Duration</Form.Label>
                                        <Form.Control type="text" value={data.rfid_duration || "Not available"} readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formRfidStartDate">
                                        <Form.Label>RFID Start Date</Form.Label>
                                        <Form.Control type="text" value={data.rfid_startdate !== '' ? (moment(data.rfid_startdate).format('DD/MM/YYYY')) : 'Not available'} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRfidEndDate">
                                        <Form.Label>RFID End Date</Form.Label>
                                        <Form.Control type="text" value={data.rfid_enddate !== '' ? (moment(data.rfid_enddate).format('DD/MM/YYYY')) : 'Not available'} readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formRfidPaymentStatus" style={{ paddingTop: "2.5rem" }}>
                                        {/* <Form.Label>RFID Payment Status</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.rfid_payment_status} label="Payment Status" readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header as="h5">Payment Methods</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formCard">
                                        {/* <Form.Label>Card</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.card} label="Card" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formWallet">
                                        {/* <Form.Label>Wallet</Form.Label> */}
                                        <Form.Check type="checkbox" checked={data.wallet} label="Wallet" readOnly />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formOfflinePayment">
                                        {/* <Form.Label>Offline Payment</Form.Label> */}
                                        <Form.Check className='pl-0' type="checkbox" checked={data.offline_payment} label="Offline Payment" readOnly />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        loading: state.report.isLoading,
        fetchSingleRfidRes: state.report.fetchSingleReportRfid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleRfid: (rfid) => dispatch(fetchSingleReportRfidAction(rfid)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewReportSingleRfid)